import React, { Fragment, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import {
	Box,
	Heading,
	Text,
	Spinner,
	Alert,
	AlertIcon,
	Table,
	Thead,
	Tbody,
	Th,
	Tr,
	Td,
	Flex,
	Button,
	Card,
	CardBody
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { PlusCircle } from 'phosphor-react'
import { AuthenticatedRequestContext } from '../App'

const CouponsPage = () => {
	const authenticatedRequest = useContext(AuthenticatedRequestContext)

	const { isLoading, isRefetching, isError, data } = useQuery(
		['coupons'],
		() =>
			authenticatedRequest
				.get('/admin/coupons')
				.then((response) => response.data),
		{ staleTime: 60000 }
	)

	return (
		<Box p="8">
			<Flex justifyContent="space-between">
				<Heading marginBottom="5">
					Coupons{isRefetching && <Spinner ml="3" />}
				</Heading>
				<Link to="/coupons/create">
					<Button rightIcon={<PlusCircle weight="bold" size={20} />}>
						Coupon erstellen
					</Button>
				</Link>
			</Flex>
			{isError ? (
				<Alert status="error">
					<AlertIcon />
					Beim Laden der Daten ist ein Fehler aufgetreten.
				</Alert>
			) : isLoading ? (
				<Spinner />
			) : (
				<Fragment>
					<Card bg="white">
						<CardBody overflowX="auto">
							{data.length ? (
								<Table>
									<Thead>
										<Tr>
											<Th>Name</Th>
											<Th>Monat</Th>
											<Th>Rabatt</Th>
											<Th>Farbe</Th>
											<Th>Gültigkeitszeitraum</Th>
											<Th>Erstellungsdatum</Th>
										</Tr>
									</Thead>
									<Tbody>
										{data.map((coupon, index) => (
											<Tr key={index}>
												<Td>
													<Link to={`/coupons/${coupon.id}`}>{coupon.name}</Link>
												</Td>
												<Td>{coupon.month}</Td>
												<Td>
													{coupon.discountAmount}{' '}
													{coupon.discountType === 'percentage' ? '%' : '€'}
												</Td>
												<Td>
													<Box bg="brand.blue" height="0.2em" width="2.2em" />
													<Box bg={coupon.color} height="0.8em" width="2.2em" />
												</Td>
												<Td
													color={
														dayjs().isBetween(
															coupon.validFrom,
															coupon.validUntil,
															'day',
															'['
														) && 'green'
													}
												>
													{dayjs(coupon.validFrom).format('DD.MM.YYYY')} bis{' '}
													{dayjs(coupon.validUntil).format('DD.MM.YYYY')}
												</Td>
												<Td>{dayjs(coupon.createdAt).format('DD. MMM YYYY')}</Td>
											</Tr>
										))}
									</Tbody>
								</Table>
							) : (
								<Alert>
									<AlertIcon />
									Keine Coupons gefunden.
								</Alert>
							)}
						</CardBody>
					</Card>
					{data.length > 0 && (
						<Text fontSize="sm" marginTop={4}>Coupons, deren Gültigkeit seit mehr als zwei Monaten abgelaufen ist, werden an dieser Stelle ausgeblendet.</Text>
					)}
  				</Fragment>
			)}
		</Box>
	)
}

export default CouponsPage
