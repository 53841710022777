import React, { forwardRef } from 'react'
import {
    Heading,
	Text,
    AspectRatio,
	Image,
	Button,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
} from '@chakra-ui/react'
import dayjs from 'dayjs'

const NotificationDialog = forwardRef(({ isOpen, onClose, notification }, ref) => (
    <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
        leastDestructiveRef={ref}
    >
        <AlertDialogOverlay>
            <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    Benachrichtigung {notification && notification.createdAt && `vom ${dayjs(notification.createdAt).format('DD.MM.YY, HH:mm')} Uhr`}
                </AlertDialogHeader>

                {notification && (
                    <AlertDialogBody>
                        <Heading size="md" marginBottom="3">{notification.title}</Heading>
                        <Text>{notification.body}</Text>
                        {notification.imageUrl && (
                            <AspectRatio ratio={13 / 8} marginTop="3">
                                <Image src={notification.imageUrl} borderRadius="xl" backgroundColor="gray.100" />
                            </AspectRatio>
                        )}
                    </AlertDialogBody>
                )}

                <AlertDialogFooter>
                    <Button
                        ref={ref}
                        onClick={onClose}
                    >
                        Schließen
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialogOverlay>
    </AlertDialog>
))

export default NotificationDialog
