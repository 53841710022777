import React, { useContext, useRef, useState, useEffect, Fragment } from 'react'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import {
	Box,
	Heading,
	Text,
	Spinner,
	Alert,
	AlertIcon,
	Table,
	Thead,
	Tbody,
	Th,
	Tr,
	Td,
    SimpleGrid,
    GridItem,
	Stack,
    HStack,
	Button,
	Card,
	CardBody,
	Input,
	Textarea,
    FormControl,
    FormLabel,
    FormHelperText,
    FormErrorMessage,
    useBreakpointValue,
    useDisclosure
} from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import dayjs from 'dayjs'
import { Check, Eye, PaperPlaneRight, X } from 'phosphor-react'
import NotificationDialog from '../components/NotificationDialog'
import { AuthenticatedRequestContext } from '../App'
import { objectIsEmpty } from '../util/data'

const NotificationsPage = () => {
	const authenticatedRequest = useContext(AuthenticatedRequestContext)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [selectedNotification, setSelectedNotification] = useState(null)
    const [cannotSendMessage, setCannotSendMessage] = useState(false)
    const cancelRef = useRef()
    const queryClient = useQueryClient()

    const openNotificationPreview = (notification) => {
      setSelectedNotification(notification)
      onOpen()
    }

	const { isLoading, isRefetching, isError, data } = useQuery(
		['notifications'],
		() =>
			authenticatedRequest
				.get('/admin/notifications')
				.then((response) => response.data),
		{ staleTime: 60000 }
	)
    
    const createMutation = useMutation({
        mutationFn: (data) =>
            authenticatedRequest.post('/admin/notifications/create', data),
        onSuccess: async (response) => {
            await queryClient.setQueryData(['notifications'], (old) => {                
                return [response.data, ...old]
            })
        }
    })

    useEffect(() => {
        const interval = setInterval(() => {
            if (!data) {
                setCannotSendMessage(true)
                return
            }

            if (data.length === 0) {
                return
            }

            const firstTimestamp = data[0].createdAt
            const fiveMinutesLater = dayjs(firstTimestamp).add(5, 'minute')

            setCannotSendMessage(dayjs().isBefore(fiveMinutesLater))
        }, 1000)

        return () => clearInterval(interval)
    }, [data])

	return (
		<Box p="8">
            <Heading marginBottom="5">
                Benachrichtigungen
            </Heading>
            <Card bg="white">
                <CardBody>
                    <SimpleGrid 
                        columns={useBreakpointValue({
                            base: 1,
                            xl: 2
                        })}
                        gap={10}
                    >
                        <GridItem>
                            <Heading size="lg" marginBottom="3">
                                Nachricht verfassen
                            </Heading>
                            <Formik
                                initialValues={{
                                    title: '',
                                    body: '',
                                    imageUrl: ''
                                }}
                                validationSchema={Yup.object({
                                    title: Yup.string().max(100).required('Pflichtfeld'),
                                    body: Yup.string().max(250).required('Pflichtfeld'),
                                    imageUrl: Yup.string().url()
                                })}
                                onSubmit={(data) => createMutation.mutate(data)}
                            >
                                    {({ values, errors, dirty, handleChange, reset }) => (
                                        <Form>
                                            <Stack alignItems="end">
                                                {createMutation.isSuccess && (
                                                    <Alert status="success">
                                                        <AlertIcon />
                                                        Die Benachrichtigung wurde erfolgreich versendet.
                                                    </Alert>
                                                )}
                                                <FormControl isRequired isInvalid={errors.title}>
                                                    <FormLabel>Titel</FormLabel>
                                                    <Input
                                                        id="title"
                                                        value={values.title}
                                                        onChange={handleChange}
                                                        disabled={createMutation.isLoading}
                                                        maxLength="100"
                                                    />
                                                    {errors.title && (
                                                        <FormErrorMessage>{errors.title}</FormErrorMessage>
                                                    )}
                                                </FormControl>
                                                <FormControl isRequired isInvalid={errors.body}>
                                                    <FormLabel>Text</FormLabel>
                                                    <Textarea
                                                        id="body"
                                                        value={values.body}
                                                        onChange={handleChange}
                                                        disabled={createMutation.isLoading}
                                                        maxLength="250"
                                                    />
                                                    {errors.body && (
                                                        <FormErrorMessage>{errors.body}</FormErrorMessage>
                                                    )}
                                                    <FormHelperText>Ein Text mit mehr als 100 Zeichen wird in Kombination mit einem Bild möglicherweise nicht vollständig angezeigt.</FormHelperText>
                                                </FormControl>
                                                <FormControl isInvalid={errors.imageUrl}>
                                                    <FormLabel>Bild-URL</FormLabel>
                                                    <Input
                                                        type="url"
                                                        id="imageUrl"
                                                        value={values.imageUrl}
                                                        onChange={handleChange}
                                                        disabled={createMutation.isLoading}
                                                    />
                                                    {errors.imageUrl && (
                                                        <FormErrorMessage>{errors.imageUrl}</FormErrorMessage>
                                                    )}
                                                    <FormHelperText>
                                                        Bitte beachten Sie, dass die Größe der Bilddatei 300 kB nicht überschreiten darf, damit das Bild auf iOS-Geräten angezeigt wird.
                                                    </FormHelperText>
                                                </FormControl>
                                                <HStack>
                                                    <Button
                                                        variant="inverted"
                                                        rightIcon={<Eye weight="bold" size={20} />}
                                                        disabled={!dirty || !objectIsEmpty(errors)}
                                                        onClick={() => openNotificationPreview({ title: values.title, body: values.body, imageUrl: values.imageUrl })}
                                                    >
                                                        Vorschau
                                                    </Button>
                                                    <Button
                                                        type="submit"
                                                        colorScheme="green"
                                                        rightIcon={<PaperPlaneRight weight="bold" size={20} />}
                                                        disabled={!dirty || !objectIsEmpty(errors) || cannotSendMessage} 
                                                        isLoading={createMutation.isLoading}
                                                        loadingText="Wird gesendet..."
                                                    >
                                                        Senden
                                                    </Button>
                                                </HStack>
                                            </Stack>
                                        </Form>
                                    )}
                            </Formik>
                        </GridItem>
                        <GridItem>
                            <Heading size="lg" marginBottom="3">
                                Historie{isRefetching && <Spinner ml="3" />}
                            </Heading>
                            {isError ? (
                                <Alert status="error">
                                    <AlertIcon />
                                    Beim Laden der Daten ist ein Fehler aufgetreten.
                                </Alert>
                            ) : isLoading ? (
                                <Spinner />
                            ) : (
                                <Fragment>
                                    {data.length ? (
                                        <Box overflowX="auto">
                                            <Table>
                                                <Thead>
                                                    <Tr>
                                                        <Th>Titel</Th>
                                                        <Th>Text</Th>
                                                        <Th whiteSpace="nowrap">Mit Bild</Th>
                                                        <Th>Datum</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {data.map((notification) => (
                                                        <Tr
                                                            onClick={() => openNotificationPreview(notification)}
                                                            _hover={{ cursor: 'pointer', background: 'gray.100' }}
                                                            key={`notification-${notification.id}`}
                                                        >
                                                            <Td maxWidth="200px"><Text isTruncated>{notification.title}</Text></Td>
                                                            <Td maxWidth="300px"><Text isTruncated>{notification.body}</Text></Td>
                                                            <Td>{notification.imageUrl ? <Check weight="bold" /> : <X weight="bold" />}</Td>
                                                            <Td>{dayjs(notification.createdAt).format('DD.MM.YY')}</Td>
                                                        </Tr>
                                                    ))}
                                                </Tbody>
                                            </Table>

                                            <NotificationDialog
                                                isOpen={isOpen}
                                                onClose={onClose}
                                                notification={selectedNotification}
                                                ref={cancelRef}
                                            />
                                        </Box>
                                    ) : (
                                        <Alert>
                                            <AlertIcon />
                                            Es wurden noch keine Benachrichtigungen versendet.
                                        </Alert>
                                    )}
                                </Fragment>
                            )}
                        </GridItem>
                    </SimpleGrid>
                </CardBody>
            </Card>
        </Box>
    )
}

export default NotificationsPage